<template>
   <div class="kitabuttons">
    <div class="container-fluid">
        <button @click="selectButton(categories[0])" :class="['btn btn-round', isKitaActive ? 'btn-active' : '']">Kita</button>
        <button @click="selectButton(categories[1])" :class="['btn btn-round', !isKitaActive ? 'btn-active' : '']">Schule</button>
    </div>
</div>
</template>

<script>
    export default {
        name: "KitaOrSchuleButtons",
        props: {
            isKitaActive: {
                type: Boolean,
                required: true,
                default: false,
            },
        },
        data: () => ({
            categories: [
            {
                label: 'Kitafinder',
                value: 'kita',
            },
            {
                label: 'Schulfinder',
                value: 'schule',
            },
            ],
        }),
        methods: {
            selectButton(type){
              this.$emit("selectedButton",type);
          }
      }
  };
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .kitabuttons {
      position: absolute;
      padding-top: 90px;
      width: 100vw;
      z-index: 1002;

      .btn{
        @media (max-width: 420px) {
            width: 100px!important;
        }
    }
}
</style>